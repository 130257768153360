// src/components/Login.js
import React from 'react';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebase';
import { toast } from 'react-toastify';

const Login = () => {
  const handleGoogleSignIn = async () => {
    console.log("Próba logowania przez Google...");
    try {
      await signInWithPopup(auth, provider);
      console.log("Logowanie pomyślne.");
      toast.success("Zalogowano pomyślnie!");
    } catch (error) {
      console.error("Błąd podczas logowania:", error);
      toast.error("Błąd podczas logowania. Spróbuj ponownie.");
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.loginBox}>
        <h2>Logowanie</h2>
        <button onClick={handleGoogleSignIn} style={styles.button}>
          Logowanie
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
  loginBox: {
    padding: '20px',
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#4285F4',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    marginTop: '20px',
  },
};

export default Login;
