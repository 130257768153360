export const themes = {
  ksiezniczkowy: {
    main: '#FFF0F5',
    panel: '#FFB6C1',
    text: '#4A4A4A',
    button: '#FF69B4',
    buttonText: '#FFFFFF',
    input: '#FFFFFF',
    inputBorder: '#FFB6C1',
    success: '#98FB98',
    error: '#FFB6C1',
  },
  rakietowy: {
    main: '#E3F2FD',
    panel: '#90CAF9',
    text: '#1A237E',
    button: '#2196F3',
    buttonText: '#FFFFFF',
    input: '#FFFFFF',
    inputBorder: '#90CAF9',
    success: '#81C784',
    error: '#EF9A9A',
  },
  krasnalowy: {
    main: '#F1F8E9',
    panel: '#AED581',
    text: '#33691E',
    button: '#7CB342',
    buttonText: '#FFFFFF',
    input: '#FFFFFF',
    inputBorder: '#AED581',
    success: '#A5D6A7',
    error: '#EF9A9A',
  },
};

export const getThemeColors = (theme) => {
  return themes[theme] || themes.ksiezniczkowy;
};
