// src/components/HistoryAndStats.js
import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';
import { FaEye, FaThumbsUp, FaTimes, FaChevronDown, FaChevronUp, FaChevronLeft, FaChevronRight, FaHistory } from 'react-icons/fa';
//import './HistoryAndStats.css';
import { getThemeColors } from '../styles/themes';
import { motion } from 'framer-motion';

const alphabet = 'AĄBCĆDEĘFGHIJKLŁMNŃOÓPRSŚTUWXYZŹŻaąbcćdeęfghijklłmnńoóprsśtuwxyzźż';

const HistoryAndStats = ({ currentUser, currentProfile, settings, currentExerciseType }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [stats, setStats] = useState(null);

  // State for filters in the stats section
  const exerciseType = currentExerciseType === 'sylaby' ? 'syllables' : 'letters';

  // Slider state
  const [sliderValue, setSliderValue] = useState(4); // 0: 1d, 1: 7d, 2: 30d, 3: 1y, 4: all
  const timeRanges = ['1d', '7d', '30d', '1y', 'all'];
  const [timeRange, setTimeRange] = useState('all'); // default to 'all'

  const [showStats, setShowStats] = useState(true);
  console.log('showStats:', showStats);
  const [showHistory, setShowHistory] = useState(true);

  const [sortColumn, setSortColumn] = useState('character');
  const [sortDirection, setSortDirection] = useState('asc');

  // Mapa kolorów: kod koloru do nazwy
  const colorMap = {
    '#000000': 'czarny',
    '#FF0000': 'czerwony',
    '#0000FF': 'niebieski',
    '#008000': 'zielony',
    '#FFFF00': 'żółty',
    // Dodaj więcej kolorów zgodnie z `Settings.js`
    // Upewnij się, że kody kolorów są zgodne z tymi używanymi w ustawieniach
  };

  // Mapa rozmiarów: wartość liczbową do symbolu
  const sizeMap = {
    50: 'S',
    100: 'M',
    150: 'L',
    200: 'XL',
  };

  const themeColors = getThemeColors(settings?.theme || 'ksiezniczkowy');

  const styles = {
    panel: {
      position: 'fixed',
      top: 0,
      right: isPanelOpen ? '0' : '-350px',
      width: '350px',
      height: '100%',
      background: `linear-gradient(135deg, ${themeColors.panel} 0%, ${themeColors.panel}ee 100%)`,
      boxShadow: '-2px 0 20px rgba(0,0,0,0.15)',
      transition: 'right 0.3s ease-in-out, background-color 0.3s',
      overflowY: 'auto',
      zIndex: 1000,
      padding: '20px',
    },
    toggleButton: {
      position: 'fixed',
      top: '60px',
      right: isPanelOpen ? '355px' : '0px',
      width: '45px',
      height: '30px',
      backgroundColor: themeColors.button,
      border: 'none',
      borderRadius: '4px 0 0 4px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'right 0.3s ease-in-out',
      zIndex: 1001,
      boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
    },
    arrow: {
      width: '0',
      height: '0',
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderLeft: isPanelOpen ? `12px solid ${themeColors.buttonText}` : 'none', // Odwrócenie kierunku
      borderRight: isPanelOpen ? 'none' : `12px solid ${themeColors.buttonText}`,
    },
    historyContent: {
      marginTop: '10px',
    },
    historyList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    historyItem: {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      padding: '12px',
      marginBottom: '10px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    },
    historyItemCorrect: {
      backgroundColor: 'rgba(230, 255, 237, 0.95)',
    },
    historyItemIncorrect: {
      backgroundColor: 'rgba(255, 230, 230, 0.95)',
    },
    historyItemSpan: {
      marginBottom: '5px',
    },
    statsContent: {
      marginTop: '10px',
      paddingBottom: '20px', // Dodaj padding na dole
    },
    statsSummary: {
      backgroundColor: '#FFFFFF',
      padding: '15px',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      marginBottom: '20px',
    },
    statsTable: {
      width: '95%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      marginBottom: '20px', // Dodaj margines na dole tabeli
    },
    statsTableHeader: {
      backgroundColor: '#f2f2f2',
      fontWeight: 'bold',
      padding: '10px 6px', // Zmniejszone padding
      borderBottom: '2px solid #ddd',
      fontSize: '12px', // Zmniejszona czcionka nagłówków
    },
    statsTableCell: {
      padding: '6px', // Zmniejszone padding
      borderBottom: '1px solid #ddd',
      textAlign: 'center',
      fontSize: '11px',
    },
    statsTableCellBold: {
      fontWeight: 'bold',
    },
    statsTableRow: {
      '&:nth-child(even)': {
        backgroundColor: '#fafafa',
      },
      '&:hover': {
        backgroundColor: '#f1f1f1',
      },
    },
    timeRangeSlider: {
      margin: '20px 0',
      backgroundColor: '#FFFFFF',
      padding: '15px',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    sliderInput: {
      WebkitAppearance: 'none',
      width: '100%',
      height: '8px',
      backgroundColor: '#ddd',
      borderRadius: '5px',
      outline: 'none',
      margin: '10px 0',
      transition: 'background 0.3s ease',
    },
    sliderLabels: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.9em',
      marginTop: '5px',
    },
    section: {
      marginBottom: '20px',
      padding: '15px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 0',
      marginBottom: '15px',
      borderBottom: '2px solid rgba(255, 255, 255, 0.1)',
      cursor: 'pointer',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '0',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    },
    th: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      padding: '12px 8px',
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: '500',
    },
    td: {
      padding: '10px 8px',
      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
    },
  };

  useEffect(() => {
    if (!currentUser?.uid || !currentProfile?.id) {
      setStats(null);
      return;
    }

    let unsubscribe;
    try {
      const responsesRef = collection(db, 'users', currentUser.uid, 'profiles', currentProfile.id, 'responses');
      unsubscribe = onSnapshot(responsesRef, () => {
        fetchStats().catch(console.error);
      });
    } catch (error) {
      console.error('Błąd podczas konfiguracji nasłuchiwania stats:', error);
      setStats(null);
    }

    return () => {
      if (unsubscribe) {
        try {
          unsubscribe();
        } catch (error) {
          console.error('Błąd podczas czyszczenia subskrypcji stats:', error);
        }
      }
    };
  }, [currentUser, currentProfile, exerciseType, timeRange]);

  useEffect(() => {
    if (!currentUser?.uid || !currentProfile?.id) {
      setHistory([]);
      return;
    }

    console.log('useEffect dla fetchHistory uruchomiony');

    let unsubscribe;
    try {
      const responsesRef = collection(db, 'users', currentUser.uid, 'profiles', currentProfile.id, 'responses');
      const q = query(responsesRef, orderBy('timestamp', 'desc'), limit(50));

      unsubscribe = onSnapshot(q, (snapshot) => {
        const historyData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHistory(historyData);
      }, (error) => {
        console.error('HistoryAndStats: Błąd podczas pobierania historii:', error);
        setHistory([]); // Wyczyść historię w przypadku błędu
      });
    } catch (error) {
      console.error('Błąd podczas konfiguracji nasłuchiwania:', error);
      setHistory([]);
    }

    return () => {
      if (unsubscribe) {
        try {
          unsubscribe();
        } catch (error) {
          console.error('Błąd podczas czyszczenia subskrypcji:', error);
        }
      }
    };
  }, [currentUser, currentProfile]);

  const fetchStats = async () => {
    if (!currentUser?.uid || !currentProfile?.id) {
      setStats(null);
      return;
    }

    try {
      const responsesRef = collection(db, 'users', currentUser.uid, 'profiles', currentProfile.id, 'responses');
      const snapshot = await getDocs(responsesRef);
      let responses = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      
      // Dodajemy szczegółowe logi
      console.log('Debug - exerciseType:', exerciseType);
      console.log('Wszystkie odpowiedzi przed filtrowaniem:', responses);
      
      // Filtrowanie odpowiedzi
      responses = responses.filter(response => {
        // Log każdej odpowiedzi i jej typu
        console.log('Sprawdzam odpowiedź:', {
          type: response.settings?.type,
          isSyllables: response.settings?.type === 'sylaby',
          shouldInclude: exerciseType === 'syllables' ? response.settings?.type === 'sylaby' : response.settings?.type !== 'sylaby'
        });

        // Sprawdzamy timestamp
        if (timeRange !== 'all') {
          const now = new Date();
          let startDate;
          switch (timeRange) {
            case '1d': startDate = new Date(now - 24 * 60 * 60 * 1000); break;
            case '7d': startDate = new Date(now - 7 * 24 * 60 * 60 * 1000); break;
            case '30d': startDate = new Date(now - 30 * 24 * 60 * 60 * 1000); break;
            case '1y': startDate = new Date(now - 365 * 24 * 60 * 60 * 1000); break;
            default: startDate = null;
          }
          if (!response.timestamp || typeof response.timestamp.toDate !== 'function') return false;
          if (response.timestamp.toDate() < startDate) return false;
        }

        // Poprawione sprawdzanie typu ćwiczenia
        const isSyllablesExercise = response.settings?.type === 'sylaby';
        const shouldInclude = exerciseType === 'syllables' ? isSyllablesExercise : !isSyllablesExercise;
        
        return shouldInclude;
      });

      console.log('Przefiltrowane odpowiedzi:', responses);

      // Obliczanie statystyk
      const totalAttempts = responses.length;
      const correctResponses = responses.filter(res => res.isCorrect).length;
      const correctPercentage = totalAttempts > 0 ? Math.round((correctResponses / totalAttempts) * 100) : 0;

      // Obliczanie statystyk dla znaków/sylab
      const characterStats = {};
      responses.forEach(res => {
        if (!res.character) return;
        
        const char = res.character;
        if (!characterStats[char]) {
          characterStats[char] = {
            character: char,
            total: 0,
            correctTotal: 0
          };
        }
        
        characterStats[char].total++;
        if (res.isCorrect) {
          characterStats[char].correctTotal++;
        }
      });

      const characterStatsArray = Object.values(characterStats)
        .sort((a, b) => a.character.localeCompare(b.character, 'pl'));

      console.log('Przetworzone statystyki:', characterStatsArray);

      setStats({
        totalAttempts,
        correctResponses,
        correctPercentage,
        characterStats: characterStatsArray,
      });

    } catch (error) {
      console.error('HistoryAndStats: Błąd podczas pobierania statystyk:', error);
      setStats(null);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return '';
    const date = timestamp.toDate();
    return date.toLocaleString('pl-PL');
  };

  // Funkcja obsługująca zmianę slidera
  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSliderValue(value);
    setTimeRange(timeRanges[value]);
  };

  const sortData = (data, column, direction) => {
    return [...data].sort((a, b) => {
      if (column === 'character') {
        return direction === 'asc' 
          ? a.character.localeCompare(b.character, 'pl') 
          : b.character.localeCompare(a.character, 'pl');
      }
      if (['total', 'upper', 'lower', 'correctTotal', 'correctUpper', 'correctLower'].includes(column)) {
        return direction === 'asc' ? a[column] - b[column] : b[column] - a[column];
      }
      // Dla kolumn z procentami
      if (['totalPercentage', 'upperPercentage', 'lowerPercentage'].includes(column)) {
        const aPercentage = column === 'totalPercentage' 
          ? (a.total > 0 ? (a.correctTotal / a.total) * 100 : 0)
          : column === 'upperPercentage'
          ? (a.upper > 0 ? (a.correctUpper / a.upper) * 100 : 0)
          : (a.lower > 0 ? (a.correctLower / a.lower) * 100 : 0);
        const bPercentage = column === 'totalPercentage'
          ? (b.total > 0 ? (b.correctTotal / b.total) * 100 : 0)
          : column === 'upperPercentage'
          ? (b.upper > 0 ? (b.correctUpper / b.upper) * 100 : 0)
          : (b.lower > 0 ? (b.correctLower / b.lower) * 100 : 0);
        return direction === 'asc' ? aPercentage - bPercentage : bPercentage - aPercentage;
      }
      return 0;
    });
  };

  const handleSort = (column) => {
    const newDirection = column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(newDirection);
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  // W komponencie, przed return
  console.log('Stan statystyk przed renderowaniem:', stats); // Log 5

  const renderStatsTable = () => {
    if (!stats?.characterStats) return null;

    const isSyllablesMode = exerciseType === 'syllables';
    console.log('Debug - renderStatsTable:');
    console.log('exerciseType:', exerciseType);
    console.log('isSyllablesMode:', isSyllablesMode);
    console.log('Statystyki:', stats.characterStats);

    return (
      <table style={styles.statsTable}>
        <thead>
          <tr>
            <th style={styles.statsTableHeader} onClick={() => handleSort('character')}>
              {isSyllablesMode ? 'Sylaba' : 'Litera'} {sortColumn === 'character' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th style={styles.statsTableHeader} onClick={() => handleSort('total')}>
              Wyświetlenia {sortColumn === 'total' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th style={styles.statsTableHeader} onClick={() => handleSort('totalPercentage')}>
              Poprawne odpowiedzi (%) {sortColumn === 'totalPercentage' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortData(stats.characterStats, sortColumn, sortDirection).map((charStat, index) => (
            <tr key={index}>
              <td style={{...styles.statsTableCell, ...styles.statsTableCellBold}}>
                {charStat.character}
              </td>
              <td style={{...styles.statsTableCell, ...styles.statsTableCellBold}}>
                {charStat.total}
              </td>
              <td style={{...styles.statsTableCell, ...styles.statsTableCellBold}}>
                {charStat.total > 0 ? Math.round((charStat.correctTotal / charStat.total) * 100) : '0'}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <motion.button
        onClick={() => setIsPanelOpen(!isPanelOpen)}
        style={styles.toggleButton}
        whileHover={{ 
          scale: 1.1,
          boxShadow: '-2px 0 10px rgba(0,0,0,0.2)',
        }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.2 }}
        aria-label={isPanelOpen ? "Zamknij panel historii" : "Otwórz panel historii"}
      >
        {isPanelOpen ? <FaChevronRight /> : <FaChevronLeft />}
      </motion.button>
      <div style={{
        ...styles.panel,
        right: isPanelOpen ? '0' : '-350px',
      }}>
        <div className="panel-content" style={{ paddingBottom: '20px' }}> {/* Dodaj padding na dole */}
          <div className="section">
            <div className="section-header" onClick={() => setShowHistory(!showHistory)}>
              <h2>Historia odpowiedzi</h2>
              {showHistory ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {showHistory && (
              <div style={styles.historyContent}>
                <ul style={styles.historyList}>
                  {history.map((item, index) => (
                    <li 
                      key={index} 
                      style={{
                        ...styles.historyItem,
                        ...(item.isCorrect ? styles.historyItemCorrect : styles.historyItemIncorrect)
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <span style={styles.historyItemSpan}><strong>Litera/Sylaba:</strong> {item.character}</span>
                        <span style={styles.historyItemSpan}>
                          <strong>Wynik:</strong> {item.isCorrect ? <FaThumbsUp /> : <FaTimes style={{color: '#dc3545'}} />}
                        </span>
                        <span style={styles.historyItemSpan}><strong>Font:</strong> {item.settings.font}</span>
                        <span style={styles.historyItemSpan}><strong>Kolor:</strong> {colorMap[item.settings.color] || item.settings.color}</span>
                        <span style={styles.historyItemSpan}><strong>Rozmiar:</strong> {sizeMap[item.settings.size] || item.settings.size}</span>
                        <span style={styles.historyItemSpan}><strong>Data:</strong> {formatDate(item.timestamp)}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="section">
            <div className="section-header" onClick={() => setShowStats(!showStats)}>
              <h2>Statystyki</h2>
              {showStats ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {showStats && (
              <div style={styles.statsContent}>
                <div style={styles.timeRangeSlider}>
                  <label htmlFor="timeRange">Zakres czasowy:</label>
                  <input
                    type="range"
                    id="timeRange"
                    name="timeRange"
                    min="0"
                    max="4"
                    step="1"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    style={styles.sliderInput}
                  />
                  <div style={styles.sliderLabels}>
                    <span>1D</span>
                    <span>7D</span>
                    <span>30D</span>
                    <span>1Y</span>
                    <span>All</span>
                  </div>
                </div>

                <div style={styles.statsSummary}>
                  <p><FaEye /> Wyświetleń: {stats ? stats.totalAttempts : 0}</p>
                  <p><FaThumbsUp /> Poprawnych: {stats ? stats.correctResponses : 0} ({stats ? stats.correctPercentage : 0}%)</p>
                </div>

                <div style={styles.statsTable}>
                  {renderStatsTable()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryAndStats;
