// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Twoja konfiguracja Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCSYF_n0Q4jqZlGLgMVS5YT8eABw88FbUM",
    authDomain: "xpdxprivate.firebaseapp.com",
    projectId: "xpdxprivate",
    storageBucket: "xpdxprivate.appspot.com",
    messagingSenderId: "283073970729",
    appId: "1:283073970729:web:6fd480eafe4fe88b6a5238",
    measurementId: "G-QXM6BFPGVL"
};

// Inicjalizacja Firebase
const app = initializeApp(firebaseConfig);

// Inicjalizacja usług Firebase
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// Eksport usług
export { auth, provider, db };
