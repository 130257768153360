import React from 'react';
import { motion } from 'framer-motion';
import { FaSignOutAlt, FaExchangeAlt, FaUser } from 'react-icons/fa';
import { getThemeColors } from '../styles/themes';

const ProfileInfo = ({ currentProfile, handleLogout, handleSwitchProfile, theme = 'ksiezniczkowy' }) => {
  const themeColors = getThemeColors(theme);

  const styles = {
    container: {
      padding: '20px',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      marginBottom: '20px',
    },
    greeting: {
      fontSize: '1.2em',
      marginBottom: '15px',
      color: themeColors.text,
    },
    profileName: {
      fontWeight: 'bold',
      fontSize: '1.55em',
    },
    buttonContainer: {
      display: 'flex',
      gap: '10px',
    },
    button: {
      flex: 1,
      padding: '8px 12px',
      border: 'none',
      borderRadius: '20px',
      cursor: 'pointer',
      fontSize: '14px',
    },
    logoutButton: {
      backgroundColor: '#f44336',
      color: '#fff',
    },
    switchButton: {
      backgroundColor: '#008CBA',
      color: '#fff',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.greeting}>
        <FaUser style={{ marginRight: '8px' }} />
        Cześć <span style={styles.profileName}>{currentProfile.name}</span>
      </div>
      <div style={styles.buttonContainer}>
        <motion.button 
          onClick={handleSwitchProfile} 
          style={{
            ...styles.button, 
            ...styles.switchButton,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          whileHover={{ scale: 1.05, boxShadow: '0 5px 15px rgba(0,139,209,0.2)' }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <FaExchangeAlt style={{ marginRight: '5px' }} /> Przełącz Profil
        </motion.button>
        <motion.button 
          onClick={handleLogout} 
          style={{
            ...styles.button, 
            ...styles.logoutButton,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          whileHover={{ scale: 1.05, boxShadow: '0 5px 15px rgba(244,67,54,0.2)' }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <FaSignOutAlt style={{ marginRight: '5px' }} /> Wyloguj się
        </motion.button>
      </div>
    </div>
  );
};

export default ProfileInfo;
