// src/components/LetterDisplay.js
import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getThemeColors } from '../styles/themes';

const LetterDisplay = ({ character, settings }) => {
  const themeColors = getThemeColors(settings?.theme || 'ksiezniczkowy');

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60vh',
      width: '100%',
    },
  };

  const getFontSize = (size) => {
    switch (size) {
      case 50:
        return '100px';
      case 100:
        return '150px';
      case 150:
        return '200px';
      case 200:
        return '250px';
      default:
        return '150px';
    }
  };

  useEffect(() => {
    console.log('🔄 Zmiana rozmiaru:', {
      poprzedni: settings?.previousSize,
      aktualny: settings?.size,
      zmienionaFontSize: getFontSize(settings?.size)
    });
  }, [settings?.size]);

  return (
    <div style={styles.container}>
      <motion.div
        key={settings?.size}
        style={{
          fontFamily: settings?.font || 'Arial',
          color: settings?.color || 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
        }}
        initial={{ fontSize: getFontSize(settings?.size), scale: 0.9 }}
        animate={{ fontSize: getFontSize(settings?.size), scale: 1 }}
        transition={{ 
          duration: 5,
          ease: "easeInOut",
          type: "tween",
          stiffness: 100
        }}
      >
        <AnimatePresence mode='wait'>
          <motion.div
            key={character}
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          >
            {character}
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default LetterDisplay;
